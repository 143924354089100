<template>
  <div :class="darkmode ? 'container-dark' : 'container-light'" class="my-3">
    <v-row no-gutters align="center" justify="space-around">
      <v-select
        v-model="perPage"
        :items="items"
        :class="darkmode || 'white'"
        :disabled="disableDropDown"
        hide-details
        outlined
        dense
        style="max-width: 100px"
        class="mr-2"
      />
      <v-btn
        :disabled="!prevPage ? true : false"
        depressed
        color="primary"
        class="mr-2"
        @click="back"
      >
        <v-icon>
          mdi-arrow-left-bold-box
        </v-icon>
        <span v-if="!xs" class="ml-2">
          {{ $t("app.previous") }}
        </span>
      </v-btn>
      <v-btn
        :disabled="!nextPage ? true : false"
        color="primary"
        depressed
        @click="next"
      >
        <span v-if="!xs" class="mr-2">
          {{ $t("app.next") }}
        </span>
        <v-icon>
          mdi-arrow-right-bold-box
        </v-icon>
      </v-btn>
      <span v-if="!xs" class="title ml-2 mt-1">
        {{ to || 0 }} / {{ total }} {{ $t("e_test.question") }}
      </span>
      <v-spacer v-if="!xs" />
      <v-text-field
        v-if="!xs"
        v-model="search"
        :label="$t('app.search')"
        :class="xs && 'my-2'"
        :disabled="disableSearch"
        outlined
        dense
        hide-details
        clearable
        style="max-width: 250px"
        prepend-inner-icon="mdi-magnify"
      />
      <div v-else class="d-flex align-center">
        <span class="mr-2" style="width: 100px">
          {{ to || 0 }} / {{ total }} {{ $t("e_test.question") }}
        </span>
        <v-text-field
          v-model="search"
          :label="$t('app.search')"
          :class="xs && 'my-2'"
          :disabled="disableSearch"
          outlined
          dense
          hide-details
          clearable
          prepend-inner-icon="mdi-magnify"
        />
      </div>
      <v-btn
        v-if="buttonDone"
        class="gradient-primary"
        :class="{ 'ml-2': !xs }"
        :block="xs"
        dark
        depressed
        @click="handlerDone"
      >
        {{ $t("app.done") }}
        <v-icon class="ml-2">mdi-checkbox-marked-circle</v-icon>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import store from "@/store";

export default {
  props: {
    currentPage: {
      type: Number,
      default: () => 0
    },
    nextPage: String,
    prevPage: String,
    to: {
      type: Number,
      default: () => 0
    },
    limit: {
      type: Number,
      default: () => 10
    },
    total: {
      type: Number,
      default: () => 0
    },
    handler: Function,
    buttonDone: Boolean,
    disableSearch: Boolean,
    disableDropDown: Boolean
  },
  watch: {
    search(value) {
      this.handleSearch(value);
    }
  },
  computed: {
    darkmode() {
      return store.getters.g_darkmode;
    },
    perPage: {
      get() {
        return this.limit;
      },
      set(n) {
        if (n) this.handler(1, n);
      }
    },
    xs() {
      return this.$vuetify.breakpoint.xs;
    }
  },
  data() {
    return {
      items: [5, 10, 25, 50, 100],
      search: ""
    };
  },
  methods: {
    handlerDone() {
      this.$emit("done");
    },
    handleSearch(value) {
      this.$emit("search", value);
    },
    next() {
      this.$emit("next", this.currentPage + 1);
    },
    back() {
      this.$emit("next", this.currentPage - 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.container-light {
  background: #eff9fd;
  border: 10px solid white;
}
.container-dark {
  background: #121212;
  border: 10px solid #424242;
}
</style>
